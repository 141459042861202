@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Anonymous+Pro&family=Inter:wght@500;700;800&family=Lato:wght@900&family=Ledger&family=Monoton&family=Montserrat:ital,wght@0,400;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ledger&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anonymous+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Ledger&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Antonio:wght@100..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');


html, body {
  min-height: 100vh;
}

/* main {
  min-height: 1000px;
} */

.container-custom {
  padding-left: calc((82% - 1284px)/2);
  padding-right: calc((82% - 1284px)/2);
}

#vectorTriangle {
  width: 100%;
  height: 100%;
  background-image: url('assets/vector-triangle.png');
  background-repeat: no-repeat;
  background-size: cover;
}

 input:checked ~ .input-card {
  background-color: white;
  color: #2c5282!important;
  border-width: 2px;
  border-color: #3182ce;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

/* Toggle */

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 3px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}